import React, { useState } from 'react';
import '../App.css';


const Header = () => {
    return (
        <div className="header">
            <h1>No More Socks</h1>
        </div>
    )
};

export default Header;